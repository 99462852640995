$primary: #2e3a59;
$secondary: #f5f5f5;
$accent: #f7a072;
$black: #000;
$white: #fff;
$warning: #f17d79;

$default-radius: 5px;

html,
body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  box-sizing: border-box;
  height: 100%;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track:hover {
  background-color: #ddd;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#root {
  height: 100%;
}

// @import "~bootstrap/scss/bootstrap";
