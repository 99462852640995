@use "../index.scss" as root;

.modal-overlay {
  background-color: rgba(root.$primary, 0.5);

  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 99;
}

.modal-container {
  background-color: root.$white;

  position: relative;
  width: 960px;
  height: 560px;
  border-radius: root.$default-radius;
}

.modal-header {
  margin: 16px 32px;
  display: flex;
  gap: 16px;
}

.modal-header .info p {
  color: root.$primary;

  margin: 0;
}

.modal-header .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-header .placeholder-image {
  width: 100px;
  height: 100px;
}

.modal-header .E {
  font-size: 48px;
}

.modal-container .line {
  display: block;
  border: 4px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modal-container .close-btn {
  float: right;
  border-radius: 0 root.$default-radius 0 0;
}

.modal-container .close-btn .icon {
  color: root.$primary;
}

.modal-container .close-btn:hover .icon {
  color: root.$secondary;
}

.modal-content {
  margin: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.information-content p,
.socials-content p {
  color: root.$primary;

  font-size: 20px;
  margin: 0;
}

.cards-container {
  margin: 16px 32px 0 32px;
  display: flex;
  gap: 16px;
}

.cards-container .icon {
  color: root.$primary;

  height: 16px;
}

.toast-container {
  background-color: root.$accent;

  display: flex;
  gap: 12px;
  align-items: center;
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%, 0);
  height: 64px;
  padding: 0 16px;
  border-radius: 30px;
}

.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 250ms ease-in, visibility 0ms ease-in 0ms;
}

.hide {
  visibility: hidden;
  opacity: 0;
  transition: opacity 250ms ease-in, visibility 0ms ease-in 250ms;
}

.toast-container span {
  color: root.$secondary;
}

.toast-container .icon-overlay {
  background-color: root.$secondary;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 32px;
  height: 32px;
}

.toast-icon {
  color: root.$primary;
  font-size: 16px;
}
