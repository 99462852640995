@use "../index.scss" as root;

.attachment-item {
  background-color: root.$primary;

  width: 150px;
  height: 40px;
  padding: 0px 8px;

  display: flex;
  align-items: center;
  gap: 8px;

  border-radius: root.$default-radius;
}

.attachment-item:hover {
  cursor: pointer;
}

.attachment-item .info > p {
  margin: 0;
}

.attachment-item .info {
  flex: 2;
}

.attachment-item .icon {
  color: root.$secondary;

  height: 28px;
}

.attachment-item .name {
  color: root.$secondary;

  font-size: 10px;
  font-weight: 700;
}

.attachment-item .size {
  color: root.$secondary;

  font-size: 8px;
}
