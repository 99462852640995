@use "./index" as root;

.email-container {
  position: relative;

  width: 300px;
  height: 100%;
  background-color: root.$primary;
}

.search-container {
  display: flex;
  justify-content: center;
  padding: 16px 25px;
}

.search {
  color: root.$primary;
  border: none;
  width: 250px;
  height: 28px;
  padding: 0 8px;
  border-radius: root.$default-radius;
}

.search:focus-visible {
  outline: none;
}

.search::placeholder {
  color: root.$primary;
}

.search-icon {
  color: root.$primary;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.nav-link {
  text-decoration: none;
}

.compose-btn {
  background-color: root.$accent;
  color: root.$secondary;

  position: absolute;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  margin: 16px 25px;
  width: 250px;
  height: 50px;
  border-radius: 5px;

  border: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  font-size: 16px;
}

.compose-btn:hover {
  cursor: pointer;
}

.dynamic-content {
  display: flex;
  width: 100%;
}

.no-mail-selected {
  color: root.$primary;

  width: 100%;
  height: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-mail-selected .icon {
  font-size: 48px;
}

.no-mail-selected p {
  margin: 0;
}
