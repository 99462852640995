@use "../index.scss" as root;

.tinker-container {
  color: root.$primary;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.tinker-container span {
  display: block;
}

.tinker-container .cog {
  color: root.$accent;

  font-size: 96px;
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tinker-container .head {
  font-size: 24px;
  font-weight: 700;
}

.tinker-container .body {
  text-align: center;
}
