@use "../index.scss" as root;

.compose-container {
  background-color: root.$white;

  min-width: 720px;
  height: 595px;
  margin: 50px 0;
  box-sizing: border-box;
  border-radius: root.$default-radius;
}

@media screen and (min-width: 1440px) {
  .compose-container {
    min-width: 1024px;
  }
}

.compose-form {
  display: flex;
  flex-direction: column;
  border-radius: root.$default-radius;
  height: 100%;
}

.compose-form input {
  color: root.$primary;

  font-size: 12px;
  height: 40px;
  padding: 0 16px;
  border: 0;
  border-bottom: 2px root.$secondary solid;
}

.compose-form input::placeholder {
  color: root.$primary;
  font-size: 12px;
}

.compose-form textarea {
  color: root.$primary;

  font-family: inherit;
  resize: none;
  flex: 2;
  border: 0;
  border-radius: 0 0 root.$default-radius root.$default-radius;
  padding: 16px;
}

.compose-form input:focus-visible,
.compose-form textarea:focus-visible {
  outline: none;
}

.compose-footer {
  background-color: root.$primary;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  height: 48px;
  border-radius: 0 0 root.$default-radius root.$default-radius;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: root.$default-radius;
  transition: background-color ease-out 300ms;
}

.close-btn:hover {
  background-color: root.$warning;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.close-btn .icon {
  color: root.$secondary;
  font-size: 16pt;
}

.send-btn {
  background-color: root.$accent;
  color: root.$secondary;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 66px;
  height: 32px;
  border-radius: root.$default-radius;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.send-btn:hover {
  cursor: pointer;
}

.send-btn span {
  font-size: 12px;
  font-weight: 700;
  user-select: none;
}
